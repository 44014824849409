import React from "react"
import HeaderStyle from "./HeaderStyle.module.styl"
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "./../../../assets/images/logo.svg"
import { Link } from "gatsby"
import { faAngleDown, faBars } from "@fortawesome/pro-regular-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import FB from "./../../../assets/images/facebook.svg"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

function Header({ toggleBurgerMenu }) {
  return (
    <div>
      <div className={HeaderStyle.info_header}>
        <div className={HeaderStyle.info_header_content}>
          <a
            href="mailto:ssgroupbg@gmail.com"
            className={HeaderStyle.info_component}
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span>ssgroupbg@gmail.com</span>
          </a>{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/%D1%83%D0%BB.+%E2%80%9E%D0%A2%D1%8A%D1%80%D0%B3%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%E2%80%9C+164,+8602+%D0%B6.%D0%BA.+%D0%A5%D0%B0%D0%BB%D0%B5,+%D0%AF%D0%BC%D0%B1%D0%BE%D0%BB/@42.4757817,26.5282116,17z/data=!4m8!1m2!2m1!1z0YPQuy4g4oCe0KLRitGA0LPQvtCy0YHQutCw4oCcIDE2NCDRj9C80LHQvtC7!3m4!1s0x40a635c8716c91f9:0x989ab0e545d25990!8m2!3d42.4755244!4d26.5281011"
            className={[
              HeaderStyle.info_component,
              HeaderStyle.address_info_component,
            ].join(" ")}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span>гр.Ямбол, ул. Търговска #164 ет.4 офис 11</span>
          </a>{" "}
          <div className={HeaderStyle.info_component}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <div className={HeaderStyle.telephones_holder}>
              <a href="tel:0893 068 000">0893 068 000 / </a>
              <a style={{ marginLeft: 3 }} href="tel:0893 068 999">
                0893 068 999
              </a>
            </div>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://m.facebook.com/ssgroupbg/"
          >
            <img src={FB} alt="fb" />{" "}
          </a>
        </div>
      </div>
      <div className={HeaderStyle.main_header}>
        <div className={HeaderStyle.main_header_content}>
          <Link className={HeaderStyle.logo} to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <div className={HeaderStyle.main_menu}>
            <Link
              to={"/"}
              activeClassName={HeaderStyle.active_main_menu}
              className={HeaderStyle.menu_item}
            >
              Начало
            </Link>
            <div className={HeaderStyle.menu_item_service}>
              Услуги <FontAwesomeIcon icon={faAngleDown} />
              <div className={HeaderStyle.services_dropdown}>
                <Link
                  to={"/signalno-ohranitelna-deinost"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  СОД
                </Link>{" "}
                <Link
                  to={"/ohranitelni-sistemi"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  Охранителни системи
                </Link>
                <Link
                  to={"/videonabludenie"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  Видеонаблюдение
                </Link>
                <Link
                  to={"/ohrana-na-meropriatia"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  Охрана на мероприятия
                </Link>
                <Link
                  to={"/fizicheska-ohrana"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  Физическа охрана
                </Link>
                <Link
                  to={"/ohrana-na-zemedelski-ploshti"}
                  activeClassName={HeaderStyle.dropdown_active_main_menu}
                  className={HeaderStyle.dropdown_main_menu_item}
                >
                  Охрана на земеделски площи
                </Link>
              </div>
            </div>
            <Link
              to={"/about-us"}
              activeClassName={HeaderStyle.active_main_menu}
              className={HeaderStyle.menu_item}
            >
              За нас
            </Link>
            <Link
              to={"/contacts"}
              activeClassName={HeaderStyle.active_main_menu}
              className={HeaderStyle.menu_item}
            >
              Контакти
            </Link>
          </div>
        </div>
        <FontAwesomeIcon
          onClick={toggleBurgerMenu}
          className={HeaderStyle.burger_icon}
          icon={faBars}
        />
      </div>
    </div>
  )
}

export default Header

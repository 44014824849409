import React from "react"
import FooterStyle from "./FooterStyle.module.styl"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"

const Footer = props => {
  return (
    <div className={FooterStyle.footer_content}>
      <div className="row">
        <div className="col-lg-3">
          <div className={FooterStyle.footer_column}>
            <h3>За нас</h3>
            <Link to="/">Начало</Link>
            <Link to="/contacts">Контакти</Link>
            <Link to="/about-us">За Special Security Group</Link>
          </div>
        </div>{" "}
        <div className="col-lg-3">
          <div className={FooterStyle.footer_column}>
            <h3>Услуги</h3>
            <Link to="/fizicheska-ohrana">Физическа охрана</Link>
            <Link to="/videonabludenie">Видеонаблюдение</Link>
            <Link to="/signalno-ohranitelna-deinost">СОД-патрулна охрана</Link>
            <Link to="/ohrana-na-meropriatia">Охрана на мероприятия</Link>
            <Link to="/ohrana-na-zemedelski-ploshti">Охрана на земеделски площи</Link>
          </div>
        </div>{" "}
        <div className="col-lg-3">
          <div className={FooterStyle.footer_column}>
            <h3>Общи</h3>
            <Link to="/biskvitki">Бисквитки</Link>
            <Link to="/politika-za-poveritelnost">Поверителност</Link>
          </div>
        </div>{" "}
        <div className="col-lg-3">
          <div className={FooterStyle.footer_column}>
            <h3>Контакти</h3>
            <a
              href="mailto:ssgroupbg@gmail.com"
              className={FooterStyle.info_component}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>ssgroupbg@gmail.com</span>
            </a>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/%D1%83%D0%BB.+%E2%80%9E%D0%A2%D1%8A%D1%80%D0%B3%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%E2%80%9C+164,+8602+%D0%B6.%D0%BA.+%D0%A5%D0%B0%D0%BB%D0%B5,+%D0%AF%D0%BC%D0%B1%D0%BE%D0%BB/@42.4757817,26.5282116,17z/data=!4m8!1m2!2m1!1z0YPQuy4g4oCe0KLRitGA0LPQvtCy0YHQutCw4oCcIDE2NCDRj9C80LHQvtC7!3m4!1s0x40a635c8716c91f9:0x989ab0e545d25990!8m2!3d42.4755244!4d26.5281011"
              className={FooterStyle.info_component}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>гр.Ямбол, ул. Търговска #164 ет.4 офис 11</span>
            </a>{" "}
            <div className={FooterStyle.info_component}>
              <FontAwesomeIcon icon={faPhoneAlt} />
              <div className={FooterStyle.telephones_holder}>
                <a href="tel:0893 068 000">0893 068 000 / </a>
                <a style={{ marginLeft: 3 }} href="tel:0893 068 999">
                  {" "}
                  0893 068 999
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={FooterStyle.all_rights_reserved}>
        Special security group | Всички права запазени 2020{" "}
        <a
          href="http://3web.bg/"
          target="_blank"
          rel="noreferrer"
          className={FooterStyle.designed_by}
        >
          Дизайн и разработка 3web Studio
        </a>
      </div>
    </div>
  )
}

export default Footer

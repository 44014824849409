import React, { useState } from "react"
import Header from "./components/Header"
import BurgerMenu from "./components/burgerMenu/BurgerMenu"

function HeaderTemplate() {
  const [isToggledBurgerMenu, setIsToggledBurgerMenu] = useState(false)
  const [
    isToggledServicesDropdownMenu,
    setIsToggledServicesDropdownMenu,
  ] = useState(false)

  const toggleBurgerMenu = () => {
    setIsToggledBurgerMenu(!isToggledBurgerMenu)
    if (isToggledBurgerMenu === false) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }
  const toggleServicesDropdown = () => {
    setIsToggledServicesDropdownMenu(!isToggledServicesDropdownMenu)
  }

  return (
    <header style={{ position: "relative" }}>
      <Header toggleBurgerMenu={() => toggleBurgerMenu()} />
      <BurgerMenu
        toggleServicesDropdown={() => toggleServicesDropdown()}
        isToggledBurgerMenu={isToggledBurgerMenu}
        toggleBurgerMenu={() => toggleBurgerMenu()}
        isToggledServicesDropdownMenu={isToggledServicesDropdownMenu}
      />
    </header>
  )
}

export default HeaderTemplate

import React, { useState } from "react"
import MobileFooterStyle from "./MobileFooterStyle.module.styl"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons"
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"

function MobileFooter() {
  const [isToggledAboutUs, setIsToggledAboutUs] = useState(false)
  const [isToggledServices, setIsToggledServices] = useState(false)
  const [isToggledCommon, setIsToggledCommon] = useState(false)
  const toggleAboutUsDropdown = () => {
    setIsToggledAboutUs(!isToggledAboutUs)
  }
  const toggleServicesDropdown = () => {
    setIsToggledServices(!isToggledServices)
  }
  const toggleCommonDropdown = () => {
    setIsToggledCommon(!isToggledCommon)
  }
  return (
    <div className={MobileFooterStyle.mobile_footer_content}>
      <div
        onClick={toggleAboutUsDropdown}
        onKeyDown={toggleAboutUsDropdown}
        role="button"
        tabIndex={"0"}
        className={MobileFooterStyle.for_the_company}
      >
        <p>За нас</p>
        <FontAwesomeIcon
          icon={isToggledAboutUs === true ? faAngleDown : faAngleRight}
        />{" "}
      </div>
      {isToggledAboutUs === true ? (
        <div className={MobileFooterStyle.footer_dropdown_content}>
          <Link to="/">Начало</Link>
          <Link to="/contacts">Контакти</Link>
          <Link to="/about-us">За Special Security Group</Link>
        </div>
      ) : null}{" "}
      <div
        onClick={toggleServicesDropdown}
        onKeyDown={toggleServicesDropdown}
        role="button"
        tabIndex={"0"}
        className={MobileFooterStyle.services}
      >
        <p>Услуги</p>
        <FontAwesomeIcon
          icon={isToggledServices === true ? faAngleDown : faAngleRight}
        />
      </div>
      {isToggledServices === true ? (
        <div className={MobileFooterStyle.footer_dropdown_content}>
          <Link to="/fizicheska-ohrana">Физическа охрана</Link>
          <Link to="/videonabludenie">Видеонаблюдение</Link>
          <Link to="/signalno-ohranitelna-deinost">СОД-патрулна охрана</Link>
          <Link to="/ohrana-na-meropriatia">Охрана на мероприятия</Link>
          <Link to="/ohrana-na-zemedelski-ploshti">
            Охрана на земеделски площи
          </Link>
        </div>
      ) : null}
      <div
        onClick={toggleCommonDropdown}
        onKeyDown={toggleCommonDropdown}
        role="button"
        tabIndex={"0"}
        className={MobileFooterStyle.services}
      >
        <p>Общи</p>
        <FontAwesomeIcon
          icon={isToggledCommon === true ? faAngleDown : faAngleRight}
        />
      </div>
      {isToggledCommon === true ? (
        <div className={MobileFooterStyle.footer_dropdown_content}>
          <Link to="/biskvitki">Бисквитки</Link>
          <Link to="/politika-za-poveritelnost">Поверителност</Link>
        </div>
      ) : null}
      <div className={MobileFooterStyle.footer_column}>
        <a
          href="mailto:ssgroupbg@gmail.com"
          className={MobileFooterStyle.info_component}
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span>ssgroupbg@gmail.com</span>
        </a>{" "}
        <div className={MobileFooterStyle.info_component}>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <span>гр.Ямбол, ул. Търговска #164 ет.4 офис 11</span>
        </div>{" "}
        <div className={MobileFooterStyle.info_component}>
          <FontAwesomeIcon icon={faPhoneAlt} />
          <div className={MobileFooterStyle.telephones_holder}>
            <a href="tel:0893 068 000">0893 068 000 / </a>
            <a style={{ marginLeft: 3 }} href="tel:0893 068 999">
              {" "}
              0893 068 999
            </a>
          </div>
        </div>
      </div>
      <div className={MobileFooterStyle.all_rights_reserved}>
        Special security group | Всички права запазени 2020
      </div>{" "}
      <a
        href="http://3web.bg/"
        target="_blank"
        rel="noreferrer"
        className={MobileFooterStyle.designed_by}
      >
        Дизайн и разработка 3web Studio
      </a>
    </div>
  )
}

export default MobileFooter

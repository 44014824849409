import React from "react"
import Footer from "./components/Footer"
import MobileFooter from "./components/mobileFooter/MobileFooter"

const FooterTemplate = props => {
  return (
    <footer style={{ background: "#001E3D", padding: "0 15px" }}>
      <Footer />
      <MobileFooter />
    </footer>
  )
}

export default FooterTemplate

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./layout.css"
import "bootstrap/dist/css/bootstrap.min.css"
import HeaderTemplate from "../header/Header.template"
import FooterTemplate from "../footer/Footer.template"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <HeaderTemplate siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <FooterTemplate />
      <CookieConsent
        location="bottom"
        buttonText="Съгласявам се"
        contentClasses="cookie-content-holder"
        buttonClasses="cookie-btn"
        buttonWrapperClasses="cookie-btn-holder"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        „Бисквитките” ни помагат да подобрим нашите услуги. Разглеждайки
        съдържанието на сайта, Вие се съгласявате и с използването на
        „бисквитки”
      </CookieConsent>
    </>
  )
}

export default Layout

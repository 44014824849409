import React from "react"
import BurgerMenuStyle from "./BurgerMenuStyle.module.styl"
import {
  faAngleDown,
  faAngleRight,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

const BurgerMenu = ({
  isToggledBurgerMenu,
  toggleBurgerMenu,
  toggleServicesDropdown,
  isToggledServicesDropdownMenu,
}) => {
  return (
    <React.Fragment>
      <div
        className={
          isToggledBurgerMenu === true
            ? BurgerMenuStyle.burger_menu_holder
            : BurgerMenuStyle.burger_menu_holder_hidden
        }
      >
        <div
          className={
            isToggledBurgerMenu === true
              ? BurgerMenuStyle.burger_menu
              : BurgerMenuStyle.burger_menu_hidden
          }
        >
          {isToggledBurgerMenu === true && (
            <div
              onClick={toggleBurgerMenu}
              onKeyDown={toggleBurgerMenu}
              role="button"
              tabIndex={"0"}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className={BurgerMenuStyle.close_burger}
              />
            </div>
          )}

          <div className={BurgerMenuStyle.mobile_links_holder}>
            <Link
              onClick={toggleBurgerMenu}
              to="/"
              className={BurgerMenuStyle.link}
              activeClassName={BurgerMenuStyle.current_page}
            >
              Начало
            </Link>
            <div className={BurgerMenuStyle.link_holder}>
              <Link
                onClick={toggleBurgerMenu}
                to={"/about-us"}
                className={BurgerMenuStyle.link}
                activeClassName={BurgerMenuStyle.current_page}
              >
                За нас
              </Link>
            </div>
            <div
              onClick={toggleServicesDropdown}
              onKeyDown={toggleServicesDropdown}
              role="button"
              tabIndex={0}
              className={BurgerMenuStyle.services}
            >
              Услуги{" "}
              <FontAwesomeIcon
                icon={
                  isToggledServicesDropdownMenu === true
                    ? faAngleRight
                    : faAngleDown
                }
              />
            </div>{" "}
            {isToggledServicesDropdownMenu === true && (
              <div className={BurgerMenuStyle.services_dropdown_menu}>
                <Link
                  onClick={toggleBurgerMenu}
                  className={BurgerMenuStyle.dropdown_link}
                  activeClassName={BurgerMenuStyle.active_dropdown_link}
                  to="/fizicheska-ohrana"
                >
                  Физическа охрана
                </Link>
                <Link
                  onClick={toggleBurgerMenu}
                  activeClassName={BurgerMenuStyle.active_dropdown_link}
                  className={BurgerMenuStyle.dropdown_link}
                  to="/videonabludenie"
                >
                  Видеонаблюдение
                </Link>
                <Link
                  onClick={toggleBurgerMenu}
                  activeClassName={BurgerMenuStyle.active_dropdown_link}
                  className={BurgerMenuStyle.dropdown_link}
                  to="/signalno-ohranitelna-deinost"
                >
                  СОД-патрулна охрана
                </Link>
                <Link
                  onClick={toggleBurgerMenu}
                  activeClassName={BurgerMenuStyle.active_dropdown_link}
                  className={BurgerMenuStyle.dropdown_link}
                  to="/ohrana-na-meropriatia"
                >
                  Охрана на мероприятия
                </Link>
                <Link
                  onClick={toggleBurgerMenu}
                  activeClassName={BurgerMenuStyle.active_dropdown_link}
                  className={BurgerMenuStyle.dropdown_link}
                  to="/ohrana-na-zemedelski-ploshti"
                >
                  Охрана на земеделски площи
                </Link>
              </div>
            )}
            <Link
              onClick={toggleBurgerMenu}
              to="/contacts"
              className={BurgerMenuStyle.link}
              activeClassName={BurgerMenuStyle.current_page}
            >
              Контакти
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BurgerMenu
